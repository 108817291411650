<template>
  <ConfirmationDialog
    v-model="dialog"
    icon="far fa-trash"
    title="USER_MANAGEMENT.USER_DELETE_REQUEST.DELETE.TITLE"
    @confirm="deleteUserDeleteRequest"
    tooltip="USER_MANAGEMENT.USER_DELETE_REQUEST.DELETE.TOOLTIP"
    confirmLabel="USER_MANAGEMENT.USER_DELETE_REQUEST.DELETE.BTN_LABEL"
    body="USER_MANAGEMENT.USER_DELETE_REQUEST.DELETE.CONTENT"
    :loading="isLoading"
  />
</template>

<script>
import ConfirmationDialog from "@components/ConfirmationDialog";

export default {
  name: "UserDeleteRequestDeleteAction",

  components: {
    ConfirmationDialog,
  },

  data() {
    return {
      dialog: false,
      isLoading: false,
    };
  },

  props: {
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },

    userDeleteRequestId: {
      type: Number,
      required: true,
      default: null,
    },

    buttonSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    async deleteUserDeleteRequest() {
      this.isLoading = true;

      try {
        await this.$axios.userManagement.deleteUserDeleteRequest({
          userDeleteRequestId: this.userDeleteRequestId,
        });
        this.dialog = false;
        this.$emit("deleted");
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
