<template>
  <ModuleSubPage fullWidth title="USER_MANAGEMENT.USER_DELETE_REQUEST.LIST.TITLE">
    <BaseHasPermission :permissionNames="['user.read']">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="auto" align-self="center" class="mr-3 mb-6 mb-md-0">
              <BaseCheckbox
                v-model="filter.hideApproved"
                label="USER_MANAGEMENT.USER_DELETE_REQUEST.LIST.HIDE_APPROVED"
              />
            </v-col>

            <v-spacer />

            <v-col cols="12" md="5" lg="4" xl="3" align-self="center">
              <BaseTableSearchField
                v-model="filter.search"
                @triggerSearch="retrieveUserDeleteRequest"
                :loading="isLoading"
                :disabled="isLoading"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="userDeleteRequest"
            :loading="isLoading"
            :options.sync="options"
            :server-items-length="meta.total"
          >
            <template #[`item.deleteAt`]="{ item }">
              {{ item.deleteAt ? dateTime(item.deleteAt) : "-" }}
            </template>

            <template #[`item.createdAt`]="{ item }">
              {{ dateTime(item.createdAt) }}
            </template>

            <template #[`item.action`]="{ item }">
              <v-row no-gutters justify="end">
                <v-col cols="auto" align-self="center">
                  <UserDeleteRequestAcceptAction
                    v-if="!item.approvedById"
                    :userDeleteRequestId="item.id"
                    icon
                    @accepted="retrieveUserDeleteRequest"
                  />
                </v-col>

                <v-col cols="auto" align-self="center">
                  <ShowUserDeleteRequestDetailIcon :userDeleteRequestId="item.id" />
                </v-col>

                <v-col cols="auto" align-self="center">
                  <UserDeleteRequestDeleteAction
                    :userDeleteRequestId="item.id"
                    icon
                    @deleted="retrieveUserDeleteRequest"
                  />
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import dateFilter from "@mixins/dateFilter";
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import ShowUserDeleteRequestDetailIcon from "./partials/ShowUserDeleteRequestDetailIcon.vue";
import UserDeleteRequestDeleteAction from "@components/Management/UserDeleteRequest/UserDeleteRequestDeleteAction";
import BaseCheckbox from "@components/Base/BaseCheckbox";
import UserDeleteRequestAcceptAction from "@components/Management/UserDeleteRequest/UserDeleteRequestAcceptAction";

export default {
  name: "UserDeleteRequestList",

  components: {
    UserDeleteRequestAcceptAction,
    BaseCheckbox,
    UserDeleteRequestDeleteAction,
    ModuleSubPage,
    ShowUserDeleteRequestDetailIcon,
  },

  mixins: [dateFilter],

  data() {
    return {
      isLoading: false,
      userDeleteRequest: [],
      filter: {
        search: null,
        hideApproved: false,
      },
      options: {
        page: 1,
        perPage: 10,
        sortBy: ["createdAt"],
        sortDesc: [true],
      },
      meta: {
        total: 0,
      },
    };
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.retrieveUserDeleteRequest();
      },
    },
    hideApproved() {
      this.retrieveUserDeleteRequest();
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("USER_MANAGEMENT.USER_DELETE_REQUEST.LIST.TABLE_HEAD_EMAIL"),
          value: "user.email",
          sortable: false,
        },
        {
          text: this.$t("USER_MANAGEMENT.USER_DELETE_REQUEST.LIST.TABLE_HEAD_FIRST_NAME"),
          value: "user.firstName",
          sortable: false,
        },
        {
          text: this.$t("USER_MANAGEMENT.USER_DELETE_REQUEST.LIST.TABLE_HEAD_LAST_NAME"),
          value: "user.lastName",
          sortable: false,
        },
        {
          text: this.$t("USER_MANAGEMENT.USER_DELETE_REQUEST.LIST.TABLE_HEAD_DELETE_AT"),
          value: "deleteAt",
          sortable: true,
        },
        {
          text: this.$t("USER_MANAGEMENT.USER_DELETE_REQUEST.LIST.TABLE_HEAD_CREATED_AT"),
          value: "createdAt",
          sortable: true,
        },
        {
          value: "action",
          sortable: false,
          align: "end",
        },
      ];
    },

    payload() {
      return {
        ...this.options,
        ...this.filter,
        perPage: this.options.perPage === -1 ? 0 : this.options.perPage,
      };
    },
  },

  methods: {
    async retrieveUserDeleteRequest() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.userManagement.listUserDeleteRequests(this.payload);
        this.userDeleteRequest = data.data;
        this.meta = data.meta;

        if (this.options.perPage === -1) {
          this.meta = {
            total: data.data.length,
          };
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
